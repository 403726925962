<template>
	<b-container>
		<b-row class="a-section-title">
			<b-col>
				<h2 class="text-center">
					<span class="z-fancy-underline w-color-primary">
						{{ $t('Partneri a garanti') }}
						<svg-fancy-underline-icon />
						<img src="./img/partners.png" class="a-small-logo" alt="">
					</span>
					<span class="w-color-black"> {{ $t('projektu') }}</span>
				</h2>
			</b-col>
		</b-row> 
		<b-row>
			<b-col class="d-flex justify-content-center">
				<img style="height: 50px" class="mr-3" src="./_assets/bratislavskykraj.png" alt="">
				<img style="height: 50px" src="./_assets/kosickykraj.png" alt="">
				<img style="height: 70px; margin-top: -10px" src="./_assets/nitrianskykraj.jpeg" alt="">
			</b-col>
		</b-row>
		<b-row class="d-flex justify-content-left">
			<b-col class="d-flex justify-content-center mt-2">
				<img style="height: 40px" class="mr-2" src="./_assets/trencianskykraj.svg" alt="">
				<img style="height: 40px" class="mr-4" src="./_assets/trnavskykraj.jpeg" alt="">
				<img style="height: 50px" class="mr-3" src="./_assets/zilinskykraj.png" alt="">
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
export default {
	components: {
		'svg-fancy-underline-icon': () => import('@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline')
	}
}
</script>

<style lang="sass" scoped>
.a-small-logo
	left: 98%!important
	margin-top: -18px


</style>